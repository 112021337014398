<template>
  <div :class="{'stags': true, 'is_more': isShowMore, 'is_disabled_more': isDisabledMore}" ref="elTags">
    <div class="list" ref="elTagsList">


      <template
        v-for="(tag, tag_id, tag_index) in list_computed"
        :key="tag_id"
      >

        <component
          v-if="!tag.isAuth || (tag.isAuth && useAuth().isAuth())"
          :is="tagAsLink ? 'a' : 'div'"
          :href="tagAsLink ? (tagLinkParams ? path + '?tags=' + tag_id : path + '/' + tag_id) : null"
          :data-group="tag.group?.replace('g','')"
          :class="{
            stag: true,
            all: tag_id === 'all',
            default: def === tag_id,
            tree_active: tagtreeactive === tag_id,
            tag_tree: tag.childs,
            active: isSelectedTag(tag_id),
            active_child: isSelectedChildTag(tag_id),
            deactivate: !tag.not_countable && getCount(tag_id) === 0 && !isSelectedTag(tag_id) && isBlokingByGroup(tag)
            }"
          @click.stop.prevent="function(){chooseTag(tag_id)}"
          v-click-outside="function(){hideTreeTag(tag_id)}"
        >
          {{ tag.trkey ? getTagTranslate(tag) : tag.name }}

          <span v-if="isShowCounts && !tag.not_countable && getCount(tag_id)" class="count">{{ getCount(tag_id) }}</span>

          <i v-if="def !== tag_id" class="icon-close close"/>

          <template v-if="tag.childs">
            <i class="icon-caret-down down"/>

            <div :class="{childs: true, tree_active: tagtreeactive === tag_id}">
              <template v-for="(child, child_id) in tag.childs">
                <div
                  :class="{'child_row': true, active: isSelectedTag(child_id)}"
                  @click.stop.prevent="chooseTreeTag(tag_id, child_id)"
                >
                  {{ child.trkey ? getTagTranslate(child) : child.name }}
                </div>
              </template>
            </div>
          </template>
        </component>
      </template>
    </div>

    <div class="toggle_background"></div>

    <div :class="{'toggle_more': true, 'active': isShowMore}" @click="toggleMore">
      <span v-if="!isShowMore" class="more">More filters</span>
      <span v-else class="less">Less filters</span>
    </div>
  </div>

</template>

<script setup>

import { watch } from 'vue';
const route = useRoute();
const router = useRouter();
const emits = defineEmits(['choose', 'unchoose', 'clear'])
const { t } = useI18n();
// const { $patcher } = useNuxtApp();



const props = defineProps({
  tags: {
    type: Object,
    required: true,
  },
  tagscount: {
    type: Array
  },
  tagsselected: {
    type: Array,
    required: true,
  },
  tagsdefault: {
    type: Object,
    required: true,
  },
  tagAsLink: {
    type: Boolean,
    default: false,
  },
  tagLinkParams:{
    type: Boolean,
    default: false,
  },
  tagLink: {
    type: String,
    default: '',
  },
  modifyPath: {
    type: Boolean,
    default: false,
  },
  isShowCounts: {
    type: Boolean,
    default: true,
  },
});

const list = ref(props.tags);
const selected = ref(props.tagsselected);
const def = ref(props.tagsdefault);
const count = ref(props.tagscount);
const tagtreeactive = ref(null);
const path = ref(props.tagLink);
const isShowMore = ref(false);
const isDisabledMore = ref(false);
const elTags = ref(null);
const elTagsList = ref(null);

watch(() => props.tagsselected, (newVal, oldVal) => {
  selected.value = newVal;
  // modifyPath();
}, {deep: true});

watch(() => props.tagscount, (newVal, oldVal) => {
  count.value = newVal
})

watch(() => props.tags, (newVal, oldVal) => {
  list.value = newVal
})

const toggleMore = function(){
  isShowMore.value = !isShowMore.value;
}

const list_computed = computed(() => {
  let res = {}

  res = list.value

  for (var s in selected.value){
    if(selected.value[s].indexOf(':') >= 0 && !isExistedTag(selected.value[s])){
      var param = selected.value[s].split(':');

      res[selected.value[s]] = {
        id: selected.value[s],
        name: param[2],
        trkey: false,
        // not_countable: ,
        is_temp: true,
      }
    }
  }

  return res;
});

const isExistedTag = function (id) {
  if (props.tags[id]) {
    return true;
  }

  for (var s_key in props.tags) {
    let s = props.tags[s_key]

    if (s['childs']) {
      for (var c_key in s.childs){
        let c = s.childs[c_key]

        if (c.id === id) {
          return true;
        }
      }
    }
  }

  return false;
}

const isSelectedTag = function (id) {
  if (id === 'all' && selected.value?.length === 0) return true;
  return selected.value?.indexOf(id) >= 0;
}

const isSelectedChildTag = function (id) {
  if(props.tags[id]['childs']) {
    for(var c in props.tags[id].childs){
      if(isSelectedTag(c))
        return true;
    }
  }

  return false;
}

// function toggleAll(){
//   if(selected.value.length == 0)
//     select('all');
//   else if(selected.value.indexOf('all') >= 0)
//     unselect('all');
// }

// function toggleDefault(){
//   if(selected.value.indexOf(def.value) >= 0 && selected.value.length > 1)
//     unselect(def.value);
//   else if(selected.value.length == 0)
//     select(def.value);
// }

// const select = function (tag_id){
//   selected.value.push(tag_id);
// }

// const unselect = function (tag_id){
//   selected.value.splice(selected.value.indexOf(tag_id), 1);
//   clearTemp(tag_id);
// }

const clearTemp = function (tag_id){
  const tag = getTag(tag_id)

  if (tag['is_temp']) {
    delete list.value[tag_id]
  }
}

const getTag = function (tag_id){
  return list.value[tag_id]
}

const getTagChild = function (tag_id, child_id){
  const tag = getTag(tag_id)
  return tag.childs[child_id]
}

const getCount = function (tag_id) {
  if (!count.value)
    return 0;

  for(var tag of count.value)
    if(tag_id === tag.id)
      return tag.count;
  return 0;
}

const hideTree = function (){
  if(tagtreeactive.value){
    tagtreeactive.value = null;
  }
}

const isBlokingByGroup = function (tag) {
  return tag.blocking_by_group ?? true;
}

const hideTreeTag = function (tag_id) {
  if (tagtreeactive.value === tag_id){
    tagtreeactive.value = null;
  }
}

const toggleTreeTag = function (tag_id) {
  tagtreeactive.value = tagtreeactive.value === tag_id ? null : tag_id;
}

const chooseTreeTag = function (tag_id, child_id) {
  const tag = getTag(tag_id)

  // unselecd for single choose
  if (tag.single_choose && tag.childs) {
    for (var c in tag.childs) {
      if (isSelectedTag(c) && c !== child_id) {
        selected.value.splice(selected.value.indexOf(c.id), 1);
      }
    }
  }  

  if (isSelectedTag(child_id)) {
    emits('unchoose', child_id);
  } else {
    emits('choose', child_id);
  }

  hideTree();

  // modifyPath();
}

const chooseTag = function (tag_id) {
  const tag = getTag(tag_id);

  if (tag.childs){
    toggleTreeTag(tag_id);
    return false;
  }

  if (tag.is_temp){
    delete list.value[tag_id];
  }

  if (tag_id === 'all') {
    emits('clear')
  } else if (isSelectedTag(tag_id)) {
    emits('unchoose', tag_id)
  } else {
    emits('choose', tag_id)
  }

  // modifyPath();
}

const getTagTranslate = function (tag){
  return Array.isArray(tag.trkey) ? tag.trkey.map(key => t(key)).join(" + ") : t(tag.trkey, tag.params)
}

const getActiveTagsName = function (){
  return Array.from(elTagsList.value.querySelectorAll('.stag.active')).map(el => el.innerText.trim()).join(' ');
}

function elTagsListHasVerticalScroll() {
  return elTagsList.value.scrollHeight > elTagsList.value.clientHeight;
}

const adoptMore = function (){
  if (import.meta.client && !elTagsListHasVerticalScroll())
    isDisabledMore.value = true
}

defineExpose({ getActiveTagsName });

onMounted(() => {
  adoptMore()
})

onUnmounted(() => {
  list.value = null;
  selected.value = null;
  def.value = null;
  count.value = null;
  path.value = null;
});
</script>

<style scoped lang="scss">
.stags {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
}

.stag {
  .list {
    position: relative;
  }
}

.stags {
  .stag {
    background-color: var(--gd-stag-back-color);
    border-radius: 30px;
    cursor: pointer;
    display: inline-block;
    padding: 5px 12px;
    position: relative;
    text-align: center;

    &:not(.active_child).deactivate {
      opacity: 0.5;
      pointer-events: none;

      &.switch {
        opacity: 0.5;
        pointer-events: all;
      }
    }
  }
}

.stags .stag .premium_label {
  color: red;
  display: inline-block;
  font-size: .9rem;
  font-weight: 700;
  align-items: center;
  margin-right: auto;
  margin-left: 0;
}

.stags .stag .premium_label::before{
  content: url(/images/icon_premium_crown.svg);
  width: 13px;
  height: 13px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: text-top;
}

.stags .stag.active .premium_label{
  color: var(--gd-stag-active-color);
}

.stags .stag.active .premium_label::before{
  filter: brightness(6);
}

.stags .stag img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 3px;
}
.stags .stag img.user{
  border-radius: 100px;
}
.stags .stag.al{
  background-color: var(--gd-stag-back-color);
}
.stags .stag .count{
  display: inline-block;
  margin-left: 5px;
  color: var(--gd-stag-color);
  font-weight: bold;
  min-width: 38px;
}
.stags .stag.active .switch,
.stags .stag.active:not(.default):not(.all) .count{
  display: none;
}
.stags .stag.active .switch,
.stags .stag.active .count{
  color: var(--gd-stag-active-color);
}
.stags .stag.system[data-id=al] > .close {
  display: none!important;
}
.stags .stag[data-id=al] > .count {
  display: inline-block!important;
  color: var(--gd-stag-color);
}
.stags .stag.active[data-id=al] > .count {
  display: inline-block!important;
  color: var(--gd-stag-active-color);
}
.stags .stag .close {
  display: none;
  margin-left: 5px;
  margin-right: -3px;
  min-width: 20px;
}
.stags .stag .switch {
  display: none;
  margin-left: 5px;
  margin-right: -3px;
  min-width: 20px;
}

.stags {
  .stag {
    &.active {
      .close {
        display: inline-block;
      }

      &:first-child {
        .close {
          display: none;
        }
      }
    }
  }
}

.stags .stag.active {
  background-color: var(--gd-stag-active-back-color);
  color: var(--gd-stag-active-color);
}
.stags .stag.switch .switch,
.stags .stag.deactivate.switch .switch{
  display: inline-block;
}
.stags .stag.switch .count,
.stags .stag.switch .close {
  display: none;
}

.stags .stag.active_child:after{
  content: ' ';
  width: 10px;
  height: 10px;
  border-radius: 10px;
  position: absolute;
  background-color: var(--gd-stag-active-back-color);
  top: 8px;
  right: 11px;
}

.stags .stag.active_child{
  background-color: var(--gd-stag-active-back-color-light);
}
/* TREE */

.childs{
  display: none;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 10;
  padding: 0;
  border-radius: 3px;
  /* width: 150px; */
  background-color: var(--gd-stag-back-color);
  border-radius: 10px;
  text-align: left;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0px 10px 17px #0000003b;
  width: 100%;
  min-width: 150px;
}

.childs.tree_active{
  display: flex;
}

.childs .child_row{
  padding: 5px 15px;
  cursor: pointer;
  color: var(--gd-stag-color)
}
.childs .child_row.active{
  background-color: var(--gd-stag-active-back-color);
  color: var(--gd-stag-active-color);
}
.childs .child_row:not(.active):hover{
  background-color: var(--un-background-color-gray);
}


/* show more */
.stags .toggle_more{
  display: none;
}
.stags .toggle_background{
  display: none;
}

@container pb (min-width: 700px){
  /*
    .stag[data-group="4"] + .stag[data-group="4"]{
      background-color: yellow;
    }


    .stag[data-group="4"]:not(.stag[data-group="4"] + .stag[data-group="4"]) {
      background-color: gray;
    }
    */

  .stag[data-group="1"] + .stag[data-group="1"]::before,
  .stag[data-group="2"] + .stag[data-group="2"]::before,
  .stag[data-group="3"] + .stag[data-group="3"]::before,
  .stag[data-group="4"] + .stag[data-group="4"]::before,
  .stag[data-group="5"] + .stag[data-group="5"]::before,
  .stag[data-group="6"] + .stag[data-group="6"]::before,
  .stag[data-group="7"] + .stag[data-group="7"]::before,
  .stag[data-group="8"] + .stag[data-group="8"]::before,
  .stag[data-group="9"] + .stag[data-group="9"]::before,
  .stag[data-group="10"] + .stag[data-group="10"]::before,
  .stag[data-group="11"] + .stag[data-group="11"]::before,
  .stag[data-group="12"] + .stag[data-group="12"]::before,
  .stag[data-group="13"] + .stag[data-group="13"]::before,
  .stag[data-group="14"] + .stag[data-group="14"]::before,
  .stag[data-group="15"] + .stag[data-group="15"]::before,
  .stag[data-group="16"] + .stag[data-group="16"]::before,
  .stag[data-group="17"] + .stag[data-group="17"]::before,
  .stag[data-group="18"] + .stag[data-group="18"]::before,
  .stag[data-group="19"] + .stag[data-group="19"]::before,
  .stag[data-group="20"] + .stag[data-group="20"]::before,
  .stag[data-group="21"] + .stag[data-group="21"]::before,
  .stag[data-group="22"] + .stag[data-group="22"]::before,
  .stag[data-group="23"] + .stag[data-group="23"]::before,
  .stag[data-group="24"] + .stag[data-group="24"]::before,
  .stag[data-group="25"] + .stag[data-group="25"]::before,
  .stag[data-group="26"] + .stag[data-group="26"]::before
  {
    content: ' ';
    white-space: pre;
    margin: 0 5px;
    position:absolute;
    width: 5px;
    height: 3px;
    background-color: var(--gd-stag-back-color);
    left: -10px;
    top: 12px;
  }
}

.stags .list{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

@container pb (max-width: 700px){
  .stags .toggle_background{
    display: block;
    content: ' ';
    background: linear-gradient(180deg, var(--un-background-color-transparent) 0%, var(--un-background-color) 90%);
    width: 100%;
    height: 57%;
    position: absolute;
    bottom: 0;
  }
  .stags.is_more .toggle_background{
    display: none;
  }
  .stags .list{
    overflow-y: hidden;
    text-align: left;
    max-height: 63px;
    position: relative;
  }
  .stags.is_more .list{
    overflow-y: inherit;
    text-align: left;
    max-height: none;
    padding-bottom: 20px;
    position: relative;
  }
  .stags .toggle_more{
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 6px;
    z-index: 1;
    left: 0;
    text-align: right;
    text-decoration: underline;
    display: block;
    text-shadow: 0 0 5px var(--un-background-color), 0 0 5px var(--un-background-color);
  }
  .stags .toggle_more.active{

    bottom: inherit;
    margin-top: -20px;
    position: relative;
  }

  .stags .toggle_more .more,
  .stags .toggle_more .less{
    padding: 9px 5px 5px 5px;
    width: 100%;
    display: block;
    text-decoration: underline;
  }


  .stags .stag{
    order: 2;
  }
  .stags .stag.active_child,
  .stags .stag.active{
    order: 1;
  }

}


/* disabled more */
.stags.is_disabled_more .toggle_more{
  display: none;
}
.stags.is_disabled_more .toggle_background{
  display: none;
}

.stags.is_disabled_more .list{
  max-height: none;
  overflow-y: inherit;
  padding-bottom: 0;
}
</style>